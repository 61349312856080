import React from 'react';
import skillsBackground from '../../assets/img/bg/skills.jpg';

const Skills = ({ skillsRef }) => (
  <div id="skills" ref={skillsRef}>
    <div className="parallax-container">
      <div className="container skills wow fadeInLeft">
        <div className="row wow fadeInLeft">
          <div className="col s12" style={{ textAlign: 'center' }}>
            <h3 className="section-title">My Skills</h3>
            <div style={{ margin: '3rem 0', display: 'flex', justifyContent: 'center' }}>
              {/* Web Icons found on http://konpa.github.io/devicon/ */}
              <i className="language-icon-svg devicon-javascript-plain" />
              <i className="language-icon-svg devicon-react-original" />
              <i className="language-icon-svg devicon-nodejs-plain" />
            </div>
          </div>
        </div>
        <div className="row skills wow fadeInLeft">
          <div className="col s12 m6">
            <p>• HTML5</p>
            <p>• CSS3</p>
            <p>• JavaScript ES7+</p>
            <p>• React 16+</p>
          </div>
          <div className="col s12 m6">
            <p>• Node.js</p>
            <p>• Express (REST APIs)</p>
            <p>• Git (GitHub, GitLab)</p>
            <p>• Java</p>
          </div>
        </div>
      </div>
      {/* .container.skills */}
      <div className="parallax">
        <img src={skillsBackground} alt="Skills Background" />
      </div>
    </div>
    {/* .parallax-container */}
  </div>
);

export default Skills;
