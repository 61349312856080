import React, { Component } from 'react';
import MyNavbar from '../../components/Navbar';
import AboutMe from '../../components/About/AboutMe';
import Skills from '../../components/About/Skills';
import Education from '../../components/About/Education';
import Contact from '../../components/About/Contact';
import Footer from '../../components/Footer';

import $ from 'jquery';

import M from 'materialize-css/dist/js/materialize.min';
import 'materialize-css/dist/css/materialize.min.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.aboutMeRef = React.createRef();
    this.skillsRef = React.createRef();
    this.educationRef = React.createRef();
    this.contactRef = React.createRef();
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.parallax');
    M.Parallax.init(elems);

    if (window.innerWidth <= 992) {
      // AboutMe Section (Swap Classes)
      $('#about .col.m4').removeClass('fadeInUp');
      $('#about .col.m4').addClass('fadeInRight');
      $('#about .col.m8').removeClass('fadeInDown');
      $('#about .col.m8').addClass('fadeInLeft');
    }
  }

  render() {
    const { refClickHandler } = this.props;
    const refs = [
      {
        name: 'About',
        location: this.aboutMeRef
      },
      {
        name: 'Skills',
        location: this.skillsRef
      },
      {
        name: 'Education',
        location: this.educationRef
      },
      {
        name: 'Contact',
        location: this.contactRef
      },
    ];
    const pages = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'Blog',
        link: '/blog'
      },
      {
        name: 'Projects',
        link: '/projects'
      }
    ];

    return (
      <div className="About page">
        <MyNavbar refClickHandler={refClickHandler} pages={pages} refs={refs} />
        <AboutMe aboutRef={this.aboutMeRef} />
        <Skills skillsRef={this.skillsRef} />
        <Education educationRef={this.educationRef} />
        <Contact contactRef={this.contactRef} backgroundColor={'#0078D7'} />
        <Footer backgroundColor={'#0078D7'} textColor={'rgba(255, 255, 255, 0.3)'} />
      </div>
    );
  }
}

export default About;
