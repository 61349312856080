import React from 'react';
import { Link } from 'react-router-dom';

const Post = ({ createdAt, desc, formatDate, slug, title }) => {
  const createdAtFormatted = formatDate(createdAt);

  return (
    <div
      className="Post card"
      style={{
        margin: '0 auto 1rem auto',
        padding: '2rem'
      }}
    >
      <div className="post-top">
        <h4 className="post-title">{title}</h4>
        <p className="post-date">{createdAtFormatted}</p>
      </div>
      <p className="post-desc">{desc}</p>
      <Link
        to={`/blog/${slug}`}
        className="waves-effect waves-light btn-large green"
      >
        Read More
    </Link>
    </div>
  );
}

export default Post;
