import React, { Component } from 'react';
import Logo from '../assets/img/mn_logo.png';
import { Link } from 'react-router-dom';

import M from 'materialize-css/dist/js/materialize.min';
import 'materialize-css/dist/css/materialize.min.css';

class MyNavbar extends Component {
  componentDidMount() {
    const elem = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elem, {});
  }

  render() {
    const { refClickHandler, pages, refs } = this.props;

    const isMacintosh = () => navigator.platform.indexOf('Mac') > -1;

    return (
      <nav className="green">
        <div className="nav-wrapper" style={{ paddingRight: isMacintosh ? null : '1.25rem' }}>
          <Link to="/" className="brand-logo" style={{ backgroundImage: `url(${Logo})` }} />
          <ul className="right hide-on-med-and-down">
            {pages ? pages.map(page => (
              <li key={page.name}>
                <Link to={page.link}>{page.name}</Link>
              </li>
            )) : null}
            {refs ? refs.map(ref => (
              <li key={ref.name}>
                <Link to="/" onClick={e => refClickHandler(ref.location, e)}>{ref.name}</Link>
              </li>
            )) : null}
          </ul>
          <ul id="slide-out" className="sidenav">
            <li className="sidenav-close sidenav-trigger-close">
              <i className="material-icons">
                close
              </i>
            </li>
            {pages ? pages.map(page => (
              <li className="sidenav-close" key={page.name}>
                <Link to={page.link}>{page.name}</Link>
              </li>
            )) : null}
            {refs ? refs.map(ref => (
              <li className="sidenav-close" key={ref.name}>
                <Link to="/" onClick={e => refClickHandler(ref.location, e)}>{ref.name}</Link>
              </li>
            )) : null}
          </ul>
          <a href="/" data-target="slide-out" className="sidenav-trigger">
            <i className="material-icons">
              menu
            </i>
          </a>
        </div>
      </nav>
    );
  }
}

export default MyNavbar;
