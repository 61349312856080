import React from 'react';
import Header from '../../components/Home/Header';
import Footer from '../../components/Footer';

const Home = ({ appLoaded }) => {
  const pages = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'About',
      link: '/about'
    },
    {
      name: 'Blog',
      link: '/blog'
    },
    {
      name: 'Projects',
      link: '/projects'
    }
  ];

  return (
    <div className="Home page">
      <Header appLoaded={appLoaded} pages={pages} />
      <Footer backgroundColor={'#0078D7'} textColor={'rgba(255, 255, 255, 0.3)'} />
    </div>
  );
}

export default Home;
