import React, { Component } from 'react';
import filmDBImg from '../../assets/img/thumbs/filmdb-home.jpg';
import MyNavbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import $ from 'jquery';

class Projects extends Component {
	componentDidMount() {
		if (window.innerWidth <= 992) {
			// Portfolio Section (Swap Classes)
			$('#main .row.title').removeClass('fadeInUp');
			$('#main .row.title').addClass('fadeInLeft');
			$('#main .row.thumb.top .col.s5, #main .row.thumb.btm .col.s5').removeClass('fadeInUpCard');
			$('#main .row.thumb.mid .col.s5').removeClass('fadeInUpCardReverse');
			$('#main .row.thumb .col.s5').addClass('zoomIn');
			$('#main .col.project-info').removeClass('fadeInDown');
			$('#main .row.thumb.top .col.project-info, #main .row.thumb.btm .col.project-info').addClass('fadeInLeft');
			$('#main .row.thumb.mid .col.project-info').addClass('fadeInRight');
		}
	}

	render() {
		const { refClickHandler } = this.props;
		const pages = [
			{
				name: 'Home',
				link: '/'
			},
			{
				name: 'About',
				link: '/about'
			},
			{
				name: 'Blog',
				link: '/blog'
			}
		];

		return (
			<div id="main" className="Projects page" ref={this.props.projectsRef} style={{ paddingTop: 'calc(6rem + 64px)' }}>
				<MyNavbar pages={pages} refClickHandler={refClickHandler} />
				<div className="container main">
					<div className="row title wow fadeInUp">
						<div className="col s12">
							<h3 className="section-title">Personal Projects</h3>
							<p className="section-subtitle">What have I been working on?</p>
						</div>
						{/* .col.s12 */}
					</div>
					{/* .row */}
					<div className="row thumb top">
						<div className="col s5 wow fadeInUpCard" style={{ animationDelay: '.25s' }}>
							<a href="https://marcusnovoa.github.io/FilmDB" target="_blank" rel="noopener noreferrer">
								<div className="card thumb" style={{ backgroundImage: `url(${filmDBImg})` }} />
							</a>
						</div>
						<div className="col project-info s7 wow fadeInDown" style={{ animationDelay: '.25s' }}>
							<h3 className="project-title">FilmDB 2.0</h3>
							<p className="project-subtitle">React.JS / JavaScript ES7 / TheMovieDB API</p>
							<p>
								FilmDB is a web-app built using React for the front-end that fetches movie data from The Movie Database (TMDb)
								API using
								the Async/Await features from JavaScript ES7. FilmDB allows users to search for their favorite movies and TV
								shows,
								navigating through a vast selection, and provides basic information through separated components. FilmDB was
								designed
								by LevelUpTuts as "LUTDB" but comes with a few additions of my own including UI changes and a search bar with
								updates
								to the state.
						</p>
							<div className="row inner">
								<div className="col s12">
									<a href="https://marcusnovoa.github.io/FilmDB" className="waves-effect waves-light btn-large white wow bounceIn"
										target="_blank" rel="noopener noreferrer">
										<i className="material-icons left">send</i>Live Demo</a>
									<a href="https://github.com/marcusnovoa/FilmDB" className="waves-effect waves-light btn-large white wow bounceIn"
										target="_blank" rel="noopener noreferrer">
										<i className="material-icons left">code</i>View Source</a>
								</div>
								{/* .col.s12 */}
							</div>
							{/* .row.inner */}
						</div>
						{/* .col.project-info */}
					</div>
					{/* .row.thumb */}
					{/* <div className="row thumb mid reverse">
							<div className="col s5 wow fadeInUpCardReverse" style={{ animationDelay: '.25s' }}>
								<a href="https://sheltered-reaches-84373.herokuapp.com/" target="_blank" rel="noopener noreferrer">
									<div className="card thumb"></div>
								</a>
							</div>
							<div className="col project-info s7 wow fadeInDown" style={{ animationDelay: '.25s' }}>
								<h3 className="project-title">FilmDB</h3>
								<p className="project-subtitle">React.JS / JavaScript ES7 / TheMovieDB API</p>
								<p>
									FilmDB is a web-app built using ReactJS for the front-end that fetches movie data from The Movie Database (TMDb) API using
									the Async/Await features from JavaScript ES7. FilmDB allows users to search for their favorite movies and TV shows,
									navigating through a vast selection, and provides basic information through separated components. FilmDB was designed
									by LevelUpTuts as "LUTDB" but comes with a few additions of my own including UI changes and a search bar with updates
									to the state.
								</p>
								<div className="row inner">
									<div className="col s12">
										<a href="https://sheltered-reaches-84373.herokuapp.com/" className="waves-effect waves-light btn-large white wow bounceIn" target="_blank" rel="noopener noreferrer">
											<i className="material-icons left">send</i>Live Demo</a>
										<a href="https://github.com/marcusnovoa/FilmDB" className="waves-effect waves-light btn-large white wow bounceIn" target="_blank" rel="noopener noreferrer">
											<i className="material-icons left">code</i>View Source</a>
									</div>
								</div>
							</div>
						</div> */}
					{/* .row.thumb */}
					{/* <div className="row thumb btm">
							<div className="col s5 wow fadeInUpCard" style="animation-delay: .25s">
								<a href="#" target="_blank" rel="noopener noreferrer">
									<div className="card thumb"></div>
								</a>
							</div>
							.col.s5
							<div className="col project-info s7 wow fadeInDown" style="animation-delay: .25s">
								<h3 className="project-title">Spotless</h3>
								<p className="project-subtitle">ReactJS / Node.js & Express / Spotify API</p>
								<p>
									Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae nesciunt sit quae! 
									Libero, ipsa commodi. Facere nihil repellat, corrupti accusamus nobis labore dolore sapiente nemo!
									Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum vel velit quod animi numquam accusamus! 
									Voluptatibus voluptatum saepe porro nemo, nesciunt illum laudantium reiciendis obcaecati.
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam, iure!
								</p>
								<div className="row inner">
									<div className="col s12">
										<a href="#" className="waves-effect waves-light btn-large white wow bounceIn" target="_blank" rel="noopener noreferrer"><i className="material-icons left">send</i>Live Demo</a>
										<a href="#" className="waves-effect waves-light btn-large white wow bounceIn" target="_blank" rel="noopener noreferrer"><i className="material-icons left">code</i>View Source</a>
									</div>
									.col.s12
								</div>
								.row.inner
							</div>
							.col.project-info
						</div>
						.row.thumb */}
				</div>
				{/* .container.main */}
				<Footer backgroundColor={'#0078D7'} textColor={'rgba(255, 255, 255, 0.3)'} />
			</div>
		);
	}
}

export default Projects;
