import React from 'react';
import aboutCardImg from '../../assets/img/about.jpg';
import Resume from '../../assets/pdf/resume.pdf';
import { Link } from 'react-router-dom';

const AboutMe = ({ aboutRef }) => (
  <div id="about" className="container about" ref={aboutRef} style={{ paddingTop: 'calc(6rem + 64px)' }}>
    <div className="row">
      <div className="col s6 m4 wow fadeInUp">
        <div className="card">
          <div className="card-image" style={{ backgroundImage: `url(${aboutCardImg})` }} />
        </div>
        {/* .card */}
      </div>
      {/* .col.m4 */}
      <div className="col s12 m8 wow fadeInDown">
        <h3 className="section-title green-text">
          Who Am I?
				</h3>
        <div className="row inner info">
          <p className="name-info">My name is Marcus Novoa.
						<span>(marrk-us noh-voh-uh)</span>
          </p>
          <p>
            I’m a code enthusiast with a passion for web development. I currently work as a full-time SWE at a subsidiary of General Dynamics.
            Back in 2015, I discovered my enjoyment of code through a single web development course. Since then, I've aimed towards improving
            my programming skills by completing personal projects and online tutorials. My development experience includes JavaScript and Java
            with tools such as React with the MERN Stack.
          </p>
          <p>In terms of work ethic, challenges keep me going. If you have an idea, I'll find a way to build it.</p>
        </div>
        <div className="row inner">
          <div className="col s12">
            <Link
              to="/resume"
              onClick={e => { e.preventDefault(); window.open(Resume); }}
              className="waves-effect waves-light btn-large green wow bounceIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="material-icons left">work</i>Resume PDF</Link>
            <a href="https://www.linkedin.com/in/marcusnovoa/" className="waves-effect waves-light btn-large green wow bounceIn"
              target="_blank" rel="noopener noreferrer">
              LinkedIn Profile
						</a>
          </div>
          {/* .col.s12 */}
        </div>
        {/* .row.inner */}
      </div>
      {/* .col.m8 */}
    </div>
    {/* .row */}
  </div>
);

export default AboutMe;
