import React from 'react';

const Contact = ({ backgroundColor, contactRef }) => (
  <div id="contact" className="contact" style={{ backgroundColor }} ref={contactRef}>
    <div className="container">
      <div className="row wow fadeInLeft">
        <div className="col s12">
          <div className="card">
            <div className="row inner text">
              <div className="col s12">
                <h3 className="section-title">Let's Work</h3>
                <p className="section-p">Feel free to reach out for a project!</p>
                <p>I'll get back to you and look forward to working with you.</p>
              </div>
              {/* .col.s12 */}
            </div>
            {/* .row.inner.text */}
            <div className="row inner icons">
              <div className="col s12 m6">
                <a href="mailto:marcus@marcuscodes.com" className="btn-floating btn-large waves-effect waves-light white" alt="Email Icon">
                  <i className="fas fa-envelope" />
                </a>
                <a href="http://www.linkedin.com/in/marcusnovoa" className="btn-floating btn-large waves-effect waves-light white"
                  target="_blank" rel="noopener noreferrer" alt="LinkedIn Icon">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="http://www.instagram.com/marcuscodes" className="btn-floating btn-large waves-effect waves-light white"
                  target="_blank" rel="noopener noreferrer" alt="Instagram Icon">
                  <i className="fab fa-instagram" />
                </a>
                <a href="http://www.twitter.com/marcuscodes" className="btn-floating btn-large waves-effect waves-light white"
                  target="_blank" rel="noopener noreferrer" alt="Twitter Icon">
                  <i className="fab fa-twitter" />
                </a>
                <a href="https://github.com/marcusnovoa" className="btn-floating btn-large waves-effect waves-light white" target="_blank" rel="noopener noreferrer"
                  alt="GitHub Icon">
                  <i className="fab fa-github-alt" />
                </a>
              </div>
              {/* .col.s12 */}
            </div>
            {/* .row.inner.icons */}
          </div>
          {/* .card */}
        </div>
        {/* .col.s12 */}
      </div>
      {/* .row */}
    </div>
    {/* .container */}
  </div>
);

export default Contact;
