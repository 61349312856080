import React from 'react';

const AppLoader = () => (
  <div id="loading-screen-con" className="green">
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
    {/* .sk-folding-cube */}
  </div>
);

export default AppLoader;
