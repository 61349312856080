import React from 'react';
import UdemyCert from '../../assets/pdf/udemy-js-certificate.pdf';

const Education = props => (
  <div id="education" className="container education" ref={props.educationRef}>
    <div className="row wow fadeIn">
      <div className="col s12">
        <h3 className="section-title">Degrees & Certifications</h3>
      </div>
      {/* .col.s12 */}
    </div>
    {/* .row */}
    <div className="row cards">
      <div className="col s12 m4">
        <div className="card wow zoomIn" style={{ animationDelay: '.25s' }}>
          <div className="green card-bg">
            <div className="col card-title">
              <i className="fas fa-book" />
              <h3 className="section-subtitle">University of</h3>
              <h3 className="section-subtitle">New Haven</h3>
            </div>
            {/* .col.card-title */}
          </div>
          {/* .card-bg */}
          <div className="col card-info">
            <p className="fut-med">B.S. Computer</p>
            <p className="fut-med">Science</p>
            <br />
            <p className="section-p">Sep 2018 - Dec 2021</p>
          </div>
          {/* .col.card-info */}
        </div>
        {/* .card */}
      </div>
      {/* .col.m4 */}
      <div className="col s12 m4">
        <div className="card wow zoomIn" style={{ animationDelay: '.5s' }}>
          <div className="green card-bg">
            <div className="col card-title">
              <i className="fas fa-graduation-cap" />
              <h3 className="section-subtitle">Borough of Manhattan</h3>
              <h3 className="section-subtitle">Community College</h3>
            </div>
            {/* .col.card-title */}
          </div>
          {/* .card-bg */}
          <div className="col card-info">
            <p className="fut-med">A.S. Multimedia</p>
            <p className="fut-med">Programming & Design</p>
            <br />
            <p className="section-p">Sep 2015 - Dec 2017</p>
          </div>
          {/* .col.card-info */}
        </div>
        {/* .card */}
      </div>
      {/* .col.m4 */}
      <div className="col s12 m4">
        <div className="card wow zoomIn" style={{ animationDelay: '.75s' }}>
          <div className="green card-bg">
            <div className="col card-title">
              <i className="fas fa-certificate" />
              <h3 className="section-subtitle">Udemy.com —</h3>
              <h3 className="section-subtitle">TraversyMedia</h3>
            </div>
            {/* .col.card-title */}
          </div>
          {/* .card-bg */}
          <div className="col card-info">
            <p className="fut-med">Modern JavaScript</p>
            <p className="fut-med">Course Completion (Cert.)</p>
            <br />
            <p className="section-p" style={{ display: 'flex' }}>Feb 2018 -
              <a
                href={`./${UdemyCert}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '.35rem' }}
              >View Here</a>
            </p>
          </div>
          {/* .col.card-info */}
        </div>
        {/* .card */}
      </div>
      {/* .col.m4 */}
    </div>
    {/* .row */}
    <div className="row quote wow fadeInLeft">
      <div className="col s12">
        <div className="card">
          <div className="row inner">
            <i className="material-icons">format_quote</i>
          </div>
          {/* .row.inner */}
          <br />
          <div className="row inner">
            <div className="col s12">
              <p>
                Marcus was a part of a program I lead at CUNY and he is a gifted software developer with the ability to quickly
								diagnose
								and solve technical problems.
							</p>
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row.inner */}
          <br />
          <div className="row inner source">
            <div className="col s12">
              <p className="section-p">Gregrian Vassell - Intel Sr. Software Architect (2007-2014)</p>
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row.inner.source */}
        </div>
        {/* .card */}
      </div>
      {/* .col.s12 */}
    </div>
    {/* .row */}
    <div className="row quote wow fadeInLeft">
      <div className="col s12">
        <div className="card">
          <div className="row inner">
            <i className="material-icons">format_quote</i>
          </div>
          {/* .row.inner */}
          <br />
          <div className="row inner">
            <div className="col s12">
              <p>
                Marcus was one of the best students I have had. His enthusiasm for programming and design led him to take
								projects far beyond
								the required assignments. He was engaged in class and always willing to help other students with questions. His
								code
								was always well organized and well commented. He regularly asked questions about projects and other programming
								topics
								outside of class. I highly recommend him for any web development or programming related position.
							</p>
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row.inner */}
          <br />
          <div className="row inner source">
            <div className="col s12">
              <p className="section-p">Owen Roberts - CUNY JavaScript Professor</p>
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row.inner.source */}
        </div>
        {/* .card */}
      </div>
      {/* .col.s12 */}
    </div>
    {/* .row */}
  </div>
);

export default Education;
