import React from 'react';

const Footer = ({ backgroundColor, textColor }) => {
  const date = new Date();
  const fullYear = date.getFullYear();

  return (
    <footer id="footer" style={{ backgroundColor }}>
      <div className="row">
        <div className="col s12">
          <p style={{ color: textColor }}>&copy; Copyright {fullYear} Marcus Novoa. All rights reserved.</p>
        </div>
        {/* .col.s12 --> */}
      </div>
      {/* .row --> */}
    </footer>
  );
}

export default Footer;
