import React, { Component } from 'react';
import MyNavbar from '../../components/Navbar';
// import Logo from '../../assets/img/mn_logo.png';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';

class Header extends Component {
  componentDidMount() {
    const options = {
      strings: ['<span>Software Engineer</span>'],
      typeSpeed: 60,
      startDelay: this.props.appLoaded ? 700 : 2300
    };
    this.typed = new Typed(this.el, options);

    document.querySelector('.typed-cursor').innerHTML = '';
  }

  render() {
    const { pages } = this.props;

    return (
      <header id="header">
        <div className="container header">
          <MyNavbar pages={pages} refClickHandler={null} />
          <div className="row header-text">
            <div
              className="col s12 animated fadeInUpBig"
              style={{ animationDelay: this.props.appLoaded ? 0 : '1.5s' }}
            >
              <h1 className="header-title white-text">Marcus Novoa</h1>
              <div className="typed-con">
                <h2>
                  <span
                    className="typed"
                    ref={el => {
                      this.el = el;
                    }}
                  />
                </h2>
              </div>
              <div className="page-links">
                <Link to="/" className="active">
                  <p>Home</p>
                </Link>
                <p>•</p>
                <Link to="/about">
                  <p>About</p>
                </Link>
                <p>•</p>
                <Link to="/blog">
                  <p>Blog</p>
                </Link>
                <p>•</p>
                <Link to="/projects">
                  <p>Projects</p>
                </Link>
              </div>
              <div className="icon-links">
                <a href="mailto:marcus@marcuscodes.com" alt="Email Icon">
                  <i className="fas fa-envelope" />
                </a>
                <a
                  href="http://www.linkedin.com/in/marcusnovoa"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="LinkedIn Icon"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <a
                  href="http://www.instagram.com/marcuscodes"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Instagram Icon"
                >
                  <i className="fab fa-instagram" />
                </a>
                <a
                  href="http://www.twitter.com/marcuscodes"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Twitter Icon"
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  href="https://github.com/marcusnovoa"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="GitHub Icon"
                >
                  <i className="fab fa-github-alt" />
                </a>
              </div>
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row */}
        </div>
        {/* .container-header */}
      </header>
    );
  }
}

export default Header;
