import React, { Component, Fragment } from 'react';
import AppLoader from '../../components/Loaders/AppLoader';
import Home from '../Pages/Home';
import About from '../Pages/About';
import Blog from '../Pages/Blog';
import PostPage from '../../components/Blog/PostPage';
import Projects from '../Pages/Projects';
import '../../scss/style.scss';

import { space, accessToken } from '../../config/api/keys';
import * as contentful from 'contentful';

import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import $ from 'jquery';

class App extends Component {
  state = {
    appLoadedForFirstTime: false
  }

  client = contentful.createClient({
    space,
    accessToken
  });

  componentDidMount() {
    $('#loading-screen-con')
      .delay(1500)
      .fadeOut(300);

    setTimeout(() => {
      this.setState({ appLoadedForFirstTime: true });
    }, 1000)
  }

  render() {
    const { appLoadedForFirstTime } = this.state;

    return (
      <Fragment>
        <AppLoader />
        <Route render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={300}
              classNames="fade">
              <Switch location={location}>
                <Route exact path='/' render={() => <Home appLoaded={appLoadedForFirstTime} />} />
                <Route exact path='/about' render={() => <About refClickHandler={this.scrollToMyRef} />} />
                <Route exact path='/blog' render={() => <Blog client={this.client} formatDate={this.formatDate} refClickHandler={this.scrollToMyRef} />} />
                <Route path='/blog/:slug' render={props => <PostPage {...props} client={this.client} formatDate={this.formatDate} />} />
                <Route exact path='/projects' render={() => <Projects refClickHandler={this.scrollToMyRef} />} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
      </Fragment>
    );
  }

  formatDate = userDate => {
    // Format from YYYY-MM-DD to MM/DD/YYYY
    let newDate = new Date(userDate).toJSON().slice(0, 10).split('-');
    newDate.push(newDate.shift());
    return newDate.join('/');
  }

  scrollToMyRef = (ref, e) => {
    if (e) e.preventDefault();
    window.scrollTo({
      top: ref.current.offsetTop - 64,
      behavior: 'smooth'
    });
  }
}

export default App;
