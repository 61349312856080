import React, { Component, Fragment } from 'react';
import MyNavbar from '../../components/Navbar';
import PageLoader from '../../components/Loaders/PageLoader';
import Post from '../../components/Blog/Post';
import Contact from '../../components/About/Contact';
import Footer from '../../components/Footer';

class Blog extends Component {
  state = {
    posts: []
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  fetchPosts = () => this.props.client.getEntries({
    'order': '-sys.createdAt'
  });

  setPosts = res => {
    this.setState({
      posts: res.items
    });
  }

  render() {
    const { posts } = this.state;
    const { formatDate, refClickHandler } = this.props;
    const postsMap = posts.map(({ fields, sys }, i) => (
      <Post
        key={i}
        createdAt={sys.createdAt}
        desc={fields.description}
        formatDate={formatDate}
        slug={fields.slug}
        title={fields.title} />
    ));
    const pages = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'About',
        link: '/about'
      },
      {
        name: 'Projects',
        link: '/projects'
      }
    ];

    return (
      <div className="Blog page" style={{ paddingTop: 'calc(6rem + 64px)' }}>
        <MyNavbar pages={pages} refClickHandler={refClickHandler} />
        <div className="container" style={{ paddingBottom: '5rem' }}>
          <div className="row">
            <div className="col s12">
              {posts.length > 0 ? (
                <Fragment>
                  <div className="wow fadeInLeft" style={{ marginBottom: 0 }}>
                    <h3>Welcome to my life</h3>
                    <p style={{ marginBottom: '2rem', textTransform: 'uppercase' }}>Check out my latest studies, travels, projects, and more.</p>
                  </div>
                  <div className="wow fadeInUp">
                    {postsMap}
                  </div>
                </Fragment>
              ) : <PageLoader />}
            </div>
            {/* .col.s12 */}
          </div>
          {/* .row */}
        </div>
        {/* .container */}
        <Contact contactRef={this.contactRef} backgroundColor={'#0078D7'} />
        <Footer backgroundColor={'#0078D7'} textColor={'rgba(255, 255, 255, 0.3)'} />
      </div>
    )
  }
}

export default Blog;
