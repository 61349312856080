import React, { Component, Fragment } from 'react';
import PageLoader from '../Loaders/PageLoader';
import MyNavbar from '../../components/Navbar';
import Contact from '../About/Contact';
import Footer from '../../components/Footer';

import uuid from 'uuid';

class PostPage extends Component {
  state = {
    post: {}
  }

  componentDidMount() {
    this.props.client.getEntries({
      'fields.slug': `${this.props.match.params.slug}`,
      'content_type': 'blog'
    })
      .then(entry => {
        this.setState({ post: entry.items[0] });
      });
  }

  render() {
    const { post } = this.state;
    let newDate;
    if (post.sys !== undefined) {
      const postSys = post.sys
      newDate = this.props.formatDate(postSys.createdAt);
    }
    const pages = [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'About',
        link: '/about'
      },
      {
        name: 'Blog',
        link: '/blog'
      },
      {
        name: 'Projects',
        link: '/projects'
      }
    ];
    const content = post.fields ? (
      post.fields.body.content.map(post => {
        const { nodeType } = post;
        let tagType;
        switch (nodeType) {
          case 'heading-1':
            tagType = 'h1';
            break;
          case 'heading-2':
            tagType = 'h2';
            break;
          case 'heading-3':
            tagType = 'h3';
            break;
          case 'heading-4':
            tagType = 'h4';
            break;
          case 'heading-5':
            tagType = 'h5';
            break;
          case 'heading-6':
            tagType = 'h6';
            break;
          case 'hr':
            return <hr key={uuid.v4()} className="inner-hr" />;
          default:
            tagType = 'p';
            break;
        }

        if (post.content[0])
          if (post.content[0].value !== '') {
            const elem = React.createElement(tagType, { key: uuid.v4() }, [post.content[0].value]);
            return elem;
          }
        if (post.data.target)
          return <img
            className="card"
            style={{ margin: '0 auto' }}
            key={post.data.target.fields.file.url}
            src={`http:${post.data.target.fields.file.url}`}
            alt={`${post.data.target.fields.description}`} />;
        return null;
      })) : null;

    return (
      <div className="PostPage page">
        <MyNavbar pages={pages} />
        <div className="container" style={{ margin: 'calc(6rem + 64px) auto 6rem auto' }}>
          <div className="row">
            <div className="col s12" style={{ display: 'flex', flexDirection: 'column' }}>
              {post.fields ? (
                <Fragment>
                  <div className="post-page-top">
                    <h3 className="post-page-title">{post.fields.title}</h3>
                    <p className="post-page-date">{newDate}</p>
                  </div>
                  <p>{post.fields.description}</p>
                  {/* <hr /> */}
                  <div className="card" style={{ marginBottom: 0, padding: '2rem' }}>
                    {content}
                  </div>
                </Fragment>
              ) : <PageLoader />
              }
            </div>
          </div>
        </div>
        <Contact contactRef={this.contactRef} backgroundColor={'#0078D7'} />
        <Footer backgroundColor={'#0078D7'} textColor={'rgba(255, 255, 255, 0.3)'} />
      </div>
    )
  }
}

export default PostPage;
